import React from 'react';
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

export default function IntroPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const mixpanel = useMixpanel();
  const type = queryParameters.get("type")
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (!mixpanel) {
      // Will be `undefined` if a token was not provided to `MixpanelProvider`
      return;
    }
    
    mixpanel.track('intro', {});
  }, [mixpanel]);

  const handleImageLoad = () => {
    setIsShow(true);
  }

  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col max-w-md mx-auto bg-black h-screen">


        <img src={require('../assets/intro_v2_1.jpg')} alt="1" />
        <img src={require('../assets/intro_v2_2.jpg')} alt="2" />
      </div>
    </div>
  )
}

function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    
    // Component unmount에 대한 클린업 로직
    return () => clearInterval(timer);
  }, []);

  // 오늘 끝까지 남은 시간을 계산하는 함수
  function calculateTimeLeft() {
    let now = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);  // 다음날 00:00:00으로 설정
    
    let diffInMs = tomorrow - now;
    let diffInSec = Math.floor(diffInMs / 1000); // 밀리초를 초로 변환

    let hours = Math.floor(diffInSec / 3600);
    diffInSec -= hours * 3600;

    let minutes = Math.floor(diffInSec / 60);
    diffInSec -= minutes * 60;

    let seconds = diffInSec;

    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  }

  function padNumber(number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  function getTimeString() {
    return timeLeft;
  }

  return (
    <div className="flex flex-row w-full justify-center" style={{ marginBottom: 20 }}>
      <p className="text-white" style={{ fontSize: 29, fontWeight: 800, marginRight: 6, fontStyle: 'normal' }}>{getTimeString()}</p>
      <p className="text-white" style={{ fontSize: 29,  }}>후 종료돼요!</p>
    </div>
  );
}