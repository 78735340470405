import React from 'react';
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

export default function MartetingPage() {
  const queryParameters = new URLSearchParams(window.location.search);
  const mixpanel = useMixpanel();
  const type = queryParameters.get("type")
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    if (!mixpanel) {
      // Will be `undefined` if a token was not provided to `MixpanelProvider`
      return;
    }

    if (type != null) {
      mixpanel.track('landing_page', {
        type
      });
    }
  }, [mixpanel]);

  const handleImageLoad = () => {
    setIsShow(true);
  }

  const onPressDownload = (click) => {
    mixpanel.track('download', {
      click,
      type
    });


    if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('track', 'ViewContent');
      }
    }

    if (type === 'naver') {
      window.open('https://streamize.page.link/app', '_blank');
      return;
    }

    if (type === 'a') {
      window.open('https://5q49.short.gy/uO73TU', '_blank');
      return;
    }

    if (type === 'b') {
      window.open('https://5q49.short.gy/v1tPeA', '_blank');
      return;
    }

    if (type === 'c') {
      window.open('https://5q49.short.gy/xbmjrG', '_blank');
      return;
    }

    if (type === 'd') {
      window.open('https://5q49.short.gy/8dKOEN', '_blank');
      return;
    }

    if (type === 'a1') {
      window.open('https://5q49.short.gy/6qBDI4', '_blank');
      return;
    }

    if (type === 'a2') {
      window.open('https://5q49.short.gy/KWSrDO', '_blank');
      return;
    }

    if (type === 'a3') {
      window.open('https://5q49.short.gy/1QfAM8', '_blank');
      return;
    }
  }

  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col max-w-md mx-auto bg-black h-screen">
        {
          type != null && (
            <div className="w-full flex flex-row justify-center items-center top-0 fixed bg-black" style={{ paddingTop: 15, paddingBottom: 15, paddingLeft: 15 }}>
              <img src={require('../assets/white_logo.png')} alt="brand" style={{ height: 30, width: 150 }} />
              <div className="flex flex-1" />
              <div
                onClick={() => onPressDownload('top')}
                className="flex cursor-pointer pl-5 pr-5 rounded-md bg-white mr-3 justify-center items-center h-[40px]">
                <p style={{ color: 'black', fontStyle: 'normal', fontWeight: 600 }}>다운로드</p>
              </div>
            </div>
          )
        }

        <img src={require('../assets/intro_v2_1.jpg')} alt="1" className={type != null ? 'pt-16' : ''}/>
        <img src={require('../assets/intro_v2_2.jpg')} alt="1" className={type != null ? 'pt-16' : ''}/>

        {
          (true) && (
            <div className="w-full cursor-pointer"
            onClick={() => onPressDownload('bottom') } 
            style={{
              width: '100%',
              // backgroundImage: 'linear-gradient(to bottom right, #00c6ff, #0072ff)',
              backgroundColor: '#c37172',
              paddingTop: 15,
              paddingBottom: 15,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <p style={{
                color: 'white',
                fontSize: 23,
                fontWeight: 'bold'
              }}>샤인 AI 다운받기</p>
            </div>
          )
        }
      </div>
    </div>
  )
}

function CountdownTimer() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    
    // Component unmount에 대한 클린업 로직
    return () => clearInterval(timer);
  }, []);

  // 오늘 끝까지 남은 시간을 계산하는 함수
  function calculateTimeLeft() {
    let now = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);  // 다음날 00:00:00으로 설정
    
    let diffInMs = tomorrow - now;
    let diffInSec = Math.floor(diffInMs / 1000); // 밀리초를 초로 변환

    let hours = Math.floor(diffInSec / 3600);
    diffInSec -= hours * 3600;

    let minutes = Math.floor(diffInSec / 60);
    diffInSec -= minutes * 60;

    let seconds = diffInSec;

    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  }

  function padNumber(number) {
    return number < 10 ? `0${number}` : number.toString();
  }

  function getTimeString() {
    return timeLeft;
  }

  return (
    <div className="flex flex-row w-full justify-center" style={{ marginBottom: 20 }}>
      <p className="text-white" style={{ fontSize: 29, fontWeight: 800, marginRight: 6, fontStyle: 'normal' }}>{getTimeString()}</p>
      <p className="text-white" style={{ fontSize: 29,  }}>후 종료돼요!</p>
    </div>
  );
}