import { Route, Routes } from 'react-router-dom';
import { MixpanelProvider } from 'react-mixpanel-browser';
import LandingPage from './pages/LandingPage';
import StartPage from './pages/StartPage';
import ResultPage from './pages/ResultPage';
import MartetingPage from './pages/MarketingPage';
import IntroPage from './pages/IntroPage';

const MIXPANEL_TOKEN = '7d97b7044b50f8bed44784d113b1bc87';
const MIXPANEL_CONFIG = {};

function App() {
  return (
    <MixpanelProvider token={MIXPANEL_TOKEN} config={MIXPANEL_CONFIG}>
      <Routes>
        <Route exact path="/brand" element={<LandingPage />} />
        <Route path="/start" element={<StartPage />} />
        <Route path="/result/:id" element={<ResultPage />} />
        <Route path="/app" element={<MartetingPage />} />
        <Route path="/intro" element={<IntroPage />} />
      </Routes>
    </MixpanelProvider>
  );
}

export default App;