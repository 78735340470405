import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'react-lottie';
import * as tmImage from '@teachablemachine/image';
import checkAnimation from '../assets/check-mark.json';
const { Kakao } = window;

const URL = 'https://teachablemachine.withgoogle.com/models/0kNQLzL83/';
const modelURL = URL + 'model.json';
const metadataURL = URL + 'metadata.json';

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export default function StartPage() {
  const navigate = useNavigate();
  const fileInput = useRef(null);
  const [image, setImage] = useState(null);
  const [uploadCompleteLottieOptions, setUploadCompleteLottieOptions] = useState(defaultOptions);
  const [isProcessPrediction, setIsProcessPrediction] = useState(false);

  const handleButtonClick = () => {
    fileInput.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    setUploadCompleteLottieOptions({
      ...defaultOptions,
      autoplay: true,
    });

    setImage(file);
  };

  const onClickAnalysisButton = async () => {
    if (isProcessPrediction) {
      return;
    }

    if (!image) {
      alert('이미지를 업로드 해주세요');
      return;
    }

    setIsProcessPrediction(true);

    const model = await tmImage.load(modelURL, metadataURL);
    const tempImage = new Image();
  
    const reader = new FileReader();
    reader.onload = async function(event) {
      tempImage.src = event.target.result;
      tempImage.onload = async function() {
        const prediction = await model.predict(tempImage, false);

        prediction.sort((a, b) => {
          return b.probability - a.probability;
        });

        const formData = new FormData();
        formData.append('file', image);
        formData.append('typeof', 'brandTestInputs');
        const uploadReponse = await axios.post('https://asia-northeast3-photostudio-15947.cloudfunctions.net/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const saveResultResponse = await axios.post('https://asia-northeast3-photostudio-15947.cloudfunctions.net/brand-test', {
          imageUrl: uploadReponse.data.url,
          results: prediction.slice(0, 5)
        });

        const docId = saveResultResponse.data.docId;

        setIsProcessPrediction(false);

        navigate(`/result/${docId}`);
      }
    }
    reader.readAsDataURL(image);
  }
  
  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col max-w-md mx-auto bg-black h-screen">
        {
          !image && (
            <div className="flex flex-1 justify-center items-center">
              <div onClick={handleButtonClick} className="flex flex-col rounded-md bg-gr w-[250px] h-[250px] justify-center items-center cursor-pointer">
                <p className="text-white font-bold">얼굴 정면 사진</p>
                <p className="text-white mb-5">하나를 업로드 해주세요</p>
                <img src={require('../assets/camera.png')} style={{ width: "25%", height: "25%", marginBottom: 20 }} alt="camera" />
                <p className="text-white font-thin">명품상 분석을 위해 필요해요</p>
              </div>
    
              <input
                ref={fileInput}
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          )
        }

        {
          image && (
            <div className="flex flex-1 justify-center items-center flex-col">
              <Lottie
                style={{ width: 200, height: 200 }}
                options={uploadCompleteLottieOptions} />

              <p className="text-white opacity-0 animate-fade-in mb-2"><strong>명품상 분석</strong> 준비가 완료되었습니다</p>
              <p className="text-white opacity-0 animate-fade-in mb-2">아래 분석하기 버튼을 클릭하세요</p>
            </div>
          )
        }

        <div className="flex flex-col p-5 justify-center items-center">
          <div
            onClick={onClickAnalysisButton}
            className="flex-row w-full bg-yellow-400 flex justify-center items-center h-12 rounded cursor-pointer">

            {
              isProcessPrediction && (
                <div role="status" className="self-center">
                  <svg aria-hidden="true" class="inline w-5 h-5 mr-3 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              )
            }
            <p className="font-bold text-black">{isProcessPrediction ? '분석중...' : '분석 시작하기'}</p>
          </div>
        </div>
      </div>
    </div>
  )
}