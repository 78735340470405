import { Link } from 'react-router-dom';

export default function LandingPage() {
  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col max-w-md mx-auto bg-black h-screen"> 
        <div className="flex flex-col justify-center pt-[80px] items-center">
          <p className="text-white text-center text-2xl font-thin mb-1">내 얼굴로 보는</p>
          <p className="text-white text-center text-2xl">명품상 테스트</p>
        </div>

        <div className="flex flex-1 justify-center items-center">
          <img src={require('../assets/brand.png')} alt="brand" style={{ height: 150, width: 200 }} />
        </div>

        <div className="flex flex-col p-5 justify-center items-center">
          
          <p className="text-white font-thin">한국인 30% 는 샤넬상이에요</p>
          <p className="text-white mb-[100px]">나는 어디 브랜드 상인지  확인해봐요!</p>
          <Link
            to="/start"
            className="w-full bg-yellow-400 flex justify-center items-center h-12 rounded cursor-pointer">
            <p className="font-bold text-black">명품상 분석하기</p>
          </Link>
        </div>
      </div>
    </div>
  )
}