import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
	FacebookShareButton,
	FacebookIcon,
	TwitterIcon,
	TwitterShareButton,
} from 'react-share';
const { Kakao } = window;

const COLOR_MAP = {
  "샤넬": "#B0B686",
  "구찌": "#3C3C3C",
  "루이비통": "#D3D7DA",
  "프라다": "#E8A735",
  "셀린느": "#6DB1BF",
  "디올": "#96823D",
  "에르메스": "#DB8429",
}

const LOGO_MAP = {
  "디올": {
    logo: require('../assets/dior.png'),
    height: 50,
    width: 120,
  },
  "에르메스": {
    logo: require('../assets/hermes.png'),
    height: 40,
    width: 150,
  },
  "구찌": {
    logo: require('../assets/gucci.png'),
    height: 40,
    width: 150,
  },
  "샤넬": {
    logo: require('../assets/chanel.png'),
    height: 100,
    width: 150
  },
  "프라다": {
    logo: require('../assets/prada.png'),
    height: 40,
    width: 150
  },
  "셀린느": {
    logo: require('../assets/celine.png'),
    height: 40,
    width: 150
  },
  "발렌시아가": {
    logo: require('../assets/balenciaga.png'),
    height: 40,
    width: 180
  },
  "루이비통": {
    logo: require('../assets/louis_vuitton.png'),
    height: 40,
    width: 220
  },
}

export default function ResultPage() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [faceImageUrl, setFaceImageUrl] = useState(null);
  const [results, setResults] = useState([]);

  const currentUrl = window.location.href;

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const { data } = await axios.get(`https://asia-northeast3-photostudio-15947.cloudfunctions.net/brand-test?docId=${id}`);
      setFaceImageUrl(data.imageUrl);
      setResults(data.results);
      setIsLoading(false);
    }

    Kakao.cleanup();
    Kakao.init('1e7904f55a308aa579556c1991209b21');
    console.log(Kakao.isInitialized());

    init();
  }, []);

  const getTop1Prediction = () => {
    if (results !== null && results.length > 0) {
      console.log("A", results)
      return LOGO_MAP[results[0]['className']]
    } else {
      return {
        logo: null,
        width: 0,
        height: 0
      }
    }
  }

  const getBarColor = (className) => {
    if (COLOR_MAP.hasOwnProperty(className)) {
      return COLOR_MAP[className];
    } else {
      return "#FFFFFF";
    }
  }

  if (isLoading) {
    return (
      <div className="container mx-auto h-screen">
        <div className="flex flex-col max-w-md mx-auto bg-black h-screen justify-center items-center">
          <div role="status" className="self-center">
            <svg aria-hidden="true" class="inline w-5 h-5 mr-3 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mx-auto h-screen">
      <div className="flex flex-col max-w-md mx-auto bg-black flex-1 p-6">
        <img src={getTop1Prediction()['logo']} alt="brand" style={{ height: getTop1Prediction()['height'], width: getTop1Prediction()['width'], alignSelf: 'center', marginBottom: 10 }} />
        <p className="text-white self-center font-thin mb-[20px]">모델로 선정되었습니다</p>
        <img src={faceImageUrl} className="rounded border-gray border-5" alt="brand" style={{ height: 300, width: '100%', marginBottom: 25 }} />

        <p className="text-white text-xl mb-[20px]">분석된 명품상 리스트</p>
        <div className="flex flex-col px-1">
          {
            results.map((item, index) => {
              return (
                <div key={index} className="flex flex-row mb-2 items-center">
                  <p className="text-white w-3/12" style={{ fontSize: 14 }}>{item.className}</p>
                  <div className="flex flex-row items-center h-[30px] rounded-sm w-8/12 mr-2" style={{ backgroundColor: '#151414'}}>
                    <div className="h-full rounded-sm" style={{ width: `${parseInt(item.probability * 100)}%`, backgroundColor: getBarColor(item.className) }}></div>
                  </div>
                  <p className="text-white text-center w-2/12" style={{ fontSize: 14 }}>{(item.probability * 100).toFixed(0).padStart(2, '0')}%</p>
                </div>
              )
            })
          }
        </div>

        <div className="flex flex-row justify-center mt-[10px]">
          <Link
            to="/"
            className="w-full bg-yellow-200 flex justify-center items-center h-10 rounded cursor-pointer mr-2">
            <p className="font-bold text-black">테스트 다시하기</p>
          </Link>

          <Link
            to="/app"
            className="w-full bg-blue-600 flex justify-center items-center h-10 rounded cursor-pointer">
            <p className="font-bold text-white">명품착용 테스트</p>
          </Link>
        </div>

        <p className="text-white text-center mt-[15px]">공유하기</p>
        <div className="flex flex-row mt-[15px] justify-center">
          <FacebookShareButton url={currentUrl} className="mr-5">
          <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
          </FacebookShareButton>
          <TwitterShareButton url={currentUrl} className="mr-5">
            <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
          </TwitterShareButton>

          <div onClick={ () => {
            window.Kakao.Link.sendScrap({
              requestUrl: currentUrl,
          });
          }} className="cursor-pointer">
            <img
              src={require('../assets/kakao_share.webp')}
              style={{ width: 48, height: 48, borderRadius: 24 }} />
          </div>
        </div>  
      </div>
    </div>
  )
}